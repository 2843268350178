<template>
    <div class="d-flex justify-content-center p-4">
        <div>
            <div class="d-flex justify-content-center">
                <b-img
                    :src="appLogoImageName"
                    alt="logo"
                    style="width: 250px;"
                />
            </div>
            <div class="mt-4 card text-center" style="font-size: 17px;">
                <div>
                    {{$t('no_clients.missing')}}.
                </div>
                <div style="font-weight: bold">
                    {{$t('no_clients.info_mail')}}
                </div>

                <div class="d-flex justify-content-center mt-2">
                    <b-button variant="primary" @click="logout">
                        <div class="mx-2">
                            {{$t('no_clients.logout')}}
                        </div>
                    </b-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import {BImg, BButton} from 'bootstrap-vue'
    import { $themeConfig } from '@themeConfig'
    import * as Sentry from '@sentry/vue'

    export default {
        components: {BImg, BButton},
        setup() {
            // App Name
            const { appLogoImageName } = $themeConfig.app
            return {
                appLogoImageName
            }
        },
        methods: {
            async logout() {
                await this.$store.dispatch('user/logout')
                this.$router.push({name: 'login'})
            },
            async loadData() {
                try {

                    const response =  await this.$http.get('/api/client/v1/assigned_clients')
                    this.allowedClients = response.data ?? []

                    let client = null

                    if (this.userData) {
                        client = this.allowedClients.find(x => x.id === this.userData.client_id)
                    }

                    if (!client && this.allowedClients.length > 0) {
                        client = this.allowedClients[0]
                    }

                    if (this.allowedClients.length > 0) {
                        await this.$store.dispatch('user/setSelectedClient', client)
                        await this.$store.dispatch('user/setUserNoClients', true)
                    } else {
                        await this.$store.dispatch('user/setUserNoClients', false)
                    }

                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.general'))
                }
            }
        },
        mounted() {
            this.loadData()
        }
    }

</script>

<style>
.card{
  border-radius: 15px;
  padding: 25px 30px;
}

.loader{
  animation: l43 1s infinite linear;
  height: 30px;
  aspect-ratio: 2.5;
}

.dark-layout .loader{
  --_g: no-repeat radial-gradient(farthest-side, #aaaaaa 90%, rgba(194, 17, 17, 0));
  background:var(--_g), var(--_g), var(--_g), var(--_g);
  background-size: 20% 50%;
}
.light-layout .loader{
  --_g: no-repeat radial-gradient(farthest-side, #606060 90%, rgba(194, 17, 17, 0));
  background:var(--_g), var(--_g), var(--_g), var(--_g);
  background-size: 20% 50%;
}

@keyframes l43 {
  0%     {background-position: calc(0*100%/3) 50% ,calc(1*100%/3) 50% ,calc(2*100%/3) 50% ,calc(3*100%/3) 50% }
  16.67% {background-position: calc(0*100%/3) 0   ,calc(1*100%/3) 50% ,calc(2*100%/3) 50% ,calc(3*100%/3) 50% }
  33.33% {background-position: calc(0*100%/3) 100%,calc(1*100%/3) 0   ,calc(2*100%/3) 50% ,calc(3*100%/3) 50% }
  50%    {background-position: calc(0*100%/3) 50% ,calc(1*100%/3) 100%,calc(2*100%/3) 0   ,calc(3*100%/3) 50% }
  66.67% {background-position: calc(0*100%/3) 50% ,calc(1*100%/3) 50% ,calc(2*100%/3) 100%,calc(3*100%/3) 0   }
  83.33% {background-position: calc(0*100%/3) 50% ,calc(1*100%/3) 50% ,calc(2*100%/3) 50% ,calc(3*100%/3) 100%}
  100%   {background-position: calc(0*100%/3) 50% ,calc(1*100%/3) 50% ,calc(2*100%/3) 50% ,calc(3*100%/3) 50% }
}

</style>